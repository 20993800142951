import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};
const OurTeam = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content row", 
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Our Team",
    paragraph: "Combined expertise for Measurement and Verification"
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-bottom col-4"
              style={{
                border: "1px solid",
                borderColor: "#d3d3d3",
                backgroundColor: "#f8f8f8"
              }}
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 fill">
                    <Image
                      style={{ minHeight: "260px", width: "195px" }}
                      src={require("./../../assets/images/me.png")}
                      alt="Features tile icon 01"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Jason Meyer, BPI</h4>
                  <p className="m-0 text-sm">
                    <b> Engineering </b> <br></br>
                    Pennsylvania State University B.S. Electrical Engineering
                    
                  </p>

                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom col-4"
              style={{
                border: "1px solid",
                borderColor: "#d3d3d3",
                backgroundColor: "#f8f8f8"
              }}
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 fill">
                    <Image
                      style={{ minHeight: "260px", width: "195px" }}
                      src={require("./../../assets/images/miles.jpg")}
                      alt="Features tile icon 01"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Miles Stapleton</h4>
                  <p className="m-0 text-sm">
                    <b> Lead Data Analyst </b> <br></br>
                    New Mexico State University B.S. Applied Mathematics
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom col-4"
              style={{
                border: "1px solid",
                borderColor: "#d3d3d3",
                backgroundColor: "#f8f8f8"
              }}
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16 fill">
                    <Image
                      style={{ minHeight: "260px", height: "260px" }}
                      src={require("./../../assets/images/steve meyer photo.jpg")}
                      alt="Features tile icon 01"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Stephen Meyer, PMP</h4>
                  <p className="m-0 text-sm">
                    <b> Engagement Manager </b> <br></br>
                    Pennsylvania State University B.S. Aerospace Engineering <br></br>
                    Georgia Institute of Technology M.S. Technology and Science Policy 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

OurTeam.propTypes = propTypes;
OurTeam.defaultProps = defaultProps;

export default OurTeam;
