import React from 'react';
import AnalyticsHero from '../components/sections/AnalyticsHero';
import AnalyticsSplit from '../components/sections/AnalyticsSplit';

const Analytics = () => {
    return(
        <>
        <AnalyticsHero />
        <AnalyticsSplit />
        </>
    )
}

export default Analytics