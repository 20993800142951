import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const FullLogo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className,
    'col-4'
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <div className="fill">
        <Link to="/">
          <Image
          className="img"
            src={require('./../../../assets/images/Eds-logo-aug.svg')}
            alt="Open" 
            />
        </Link>
      </div>
    </div>
  );
}

export default FullLogo;