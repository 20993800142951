import React from 'react';
import SectionHeader from "../components/sections/partials/SectionHeader";
import classNames from "classnames";
import AuditChecklist from '../components/sections/AuditChecklist';
import Image from "../components/elements/Image";


const Audit = ( {
 className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
    const outerClasses = classNames(
        "features-split section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
      );
    
      const innerClasses = classNames(
        "features-split-inner section-inner",
        topDivider && "has-top-divider",
        bottomDivider && "has-bottom-divider"
      );
    const sectionHeader = {
        title: "In-Person and Virtual Assessments",
        paragraph:
          "We perform energy efficiency audits by either In-Home Checkups or by Virtual Assessments. With the Virtual option, our experts review the home through the homeowner's mobile device."
      };
    return(
      <>
        <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div
            className="hero-figure reveal-from-bottom illustration-element-01 center-content"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <Image
              className="has-shadow"
              src={require("./../assets/images/blower-test.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />
          </div>
        </div>
        </div>
       
        </section>
        <AuditChecklist />
        </>
    )

}

export default Audit