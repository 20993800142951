import React from 'react';
import ReferencesList from '../components/sections/ReferencesList';

const References = () => {
    return(
        <>
        <ReferencesList />
        </>
    )
}

export default References