import React from 'react';
import BaselineHero from '../components/sections/BaselineHero';
import BaselineSplit from '../components/sections/BaselineSplit';

const Baseline = () => {
    return(
        <>
        <BaselineHero />
        <BaselineSplit />
        </>
    )
}

export default Baseline