import React from 'react';
import InstallationHero from '../components/sections/InstallationHero';
import InstallationSplit from '../components/sections/InstallationSplit';

const Installation = () => {
    return(
        <>
        <InstallationHero />
        <InstallationSplit />
        </>
    )
}

export default Installation