import React, { useRef, useEffect } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import ReactGA from "react-ga";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import Home from "./views/Home";
import Contact from "./views/Contact";
import AboutUs from "./views/AboutUs";
import OurServices from "./views/OurServices";
import Audit from "./views/Audit";
import Installation from "./views/Installation";
import Reporting from "./views/Reporting";
import Faq from "./views/Faq";
import EnergyEvaluation from "./views/EnergyEvaluation";
import Methods from "./views/Methods";
import Baseline from "./views/Baseline";
import MarketResearch from  "./views/MarketResearch";
import Analytics from  "./views/Analytics";
import EconomicImpacts from "./views/EconomicImpact";
import References from "./views/References";



// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute
            exact
            path="/contact-us"
            component={Contact}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/about-us"
            component={AboutUs}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/our-services"
            component={OurServices}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/services/audits"
            component={Audit}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/services/installations"
            component={Installation}
            layout={LayoutDefault}
          />
           <AppRoute
            exact
            path="/faq"
            component={Faq}
            layout={LayoutDefault}
          />
           <AppRoute
            exact
            path="/services"
            component={EnergyEvaluation}
            layout={LayoutDefault}
          />
           <AppRoute
            exact
            path="/services/baseline"
            component={Baseline}
            layout={LayoutDefault}
          />
           <AppRoute
            exact
            path="/services/analytics"
            component={Analytics}
            layout={LayoutDefault}
          />
            <AppRoute
            exact
            path="/services/economic-impacts"
            component={EconomicImpacts}
            layout={LayoutDefault}
          />
           <AppRoute
            exact
            path="/services/references"
            component={References}
            layout={LayoutDefault}
          />
          
        </Switch>
      )}
    />
  );
};

export default App;
