import React from 'react';
import EconomicHero from '../components/sections/EconomicHero';
import EconomicSplit from '../components/sections/EconomicSplit';

const EconomicImpacts = () => {
    return(
        <>
        <EconomicHero />
        <EconomicSplit />
        </>
    )
}

export default EconomicImpacts