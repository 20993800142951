import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};
const AuditChecklist = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Energy Audit Checklist",
    paragraph:
      "These are the steps taken by our Energy Efficiency Experts to assess how much energy is wasted."
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container center-content ">
        <SectionHeader data={sectionHeader} className="center-content" />
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="reveal-from-bottom checklist-item">
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-1st-96.png")}
                    alt="Features tile icon 01"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">Energy Bill Analysis</h4>
                <p className="m-0 text-sm">
                  Analyze past year's energy bills to determine base energy
                  consumption.
                </p>
              </div>
            </div>

            <div
              className="reveal-from-bottom checklist-item"
              data-reveal-delay="200"
            >
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-circled-2-96.png")}
                    alt="Features tile icon 02"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">Customer Interview</h4>
                <p className="m-0 text-sm">
                  Interview the homeowner about the home's efficiency
                  opportunities, how the home operates, and explain the audit
                  process.
                </p>
              </div>
            </div>

            <div
              className="reveal-from-bottom checklist-item"
              data-reveal-delay="400"
            >
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-circled-3-96.png")}
                    alt="Features tile icon 03"
                    width={64}
                    height={64}
                  />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Inspections</h4>
                  <p className="m-0 text-sm">
                    Conduct exterior and interior visual inspections, including
                    windows, doors and attic.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End of 1st Column */}

          {/* 2nd Column */}
          <div className="col-12 col-md-6">
            <div className="reveal-from-bottom  checklist-item">
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-circled-4-96.png")}
                    alt="Features tile icon 04"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">
                  Heating, Cooling and Home Appliances Inspection
                </h4>
                <p className="m-0 text-sm">
                  Inspect the homeowner's HVAC, water heating, refrigerator,
                  washer and dryer to assess efficiences and determine rebate
                  eligibility.
                </p>
              </div>
            </div>
            <div
              className="reveal-from-bottom checklist-item"
              data-reveal-delay="200"
            >
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-circled-5-96.png")}
                    alt="Features tile icon 05"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">Blower door test</h4>
                <p className="m-0 text-sm">
                  {" "}
                  Powerful fans installed on an external door frame to
                  determines leaks that maybe responsible for unnecessary
                  heating/cooling loss.{" "}
                </p>
              </div>
            </div>

            <div
              className="reveal-from-bottom  checklist-item"
              data-reveal-delay="400"
            >
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-circled-6-96.png")}
                    alt="Features tile icon 06"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">Comprehensive Energy Report</h4>
                <p className="m-0 text-sm" style={{ textAlign: "center" }}>
                The Home Energy Score methodology, developed by the Department
                  of Energy, estimates home energy use, associated costs, and
                  provides energy solutions to cost-effectively improve the
                  home’s efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AuditChecklist.propTypes = propTypes;
AuditChecklist.defaultProps = defaultProps;

export default AuditChecklist;
